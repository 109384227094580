export const NavData = [
  {
    id: 1,
    name: "Services",
    slug: "/#services",
  },
  {
    id: 2,
    name: "Case Study",
    slug: "/#case-study",
  },
  {
    id: 3,
    name: "Mentoring",
    slug: "/#mentoring",
  },
  /*
  {
    id: 4,
    name: "Blog",
    slug: "/blog",
  },
  */
]
